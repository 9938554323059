exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-financial-information-corporate-governance-js": () => import("./../../../src/pages/financial-information/corporate-governance.js" /* webpackChunkName: "component---src-pages-financial-information-corporate-governance-js" */),
  "component---src-pages-financial-information-financial-reports-js": () => import("./../../../src/pages/financial-information/financial-reports.js" /* webpackChunkName: "component---src-pages-financial-information-financial-reports-js" */),
  "component---src-pages-financial-information-rights-issue-2023-js": () => import("./../../../src/pages/financial-information/rights-issue-2023.js" /* webpackChunkName: "component---src-pages-financial-information-rights-issue-2023-js" */),
  "component---src-pages-financial-information-stock-information-js": () => import("./../../../src/pages/financial-information/stock-information.js" /* webpackChunkName: "component---src-pages-financial-information-stock-information-js" */),
  "component---src-pages-group-js": () => import("./../../../src/pages/group.js" /* webpackChunkName: "component---src-pages-group-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-[slug]-js": () => import("./../../../src/pages/news/[slug].js" /* webpackChunkName: "component---src-pages-news-[slug]-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-ssr-js": () => import("./../../../src/pages/ssr.js" /* webpackChunkName: "component---src-pages-ssr-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/page-template.js" /* webpackChunkName: "component---src-templates-page-template-js" */)
}

